import { ChatbotId } from '@/utils/contants';
import { useEffect } from 'react';

export const removeElement = (elementId: string): void => {
  const element = document.getElementById(elementId);
  if (element) element.remove();
};

export const removeScripts = (selector: string): void => {
  const scripts = document.querySelectorAll(selector);
  scripts.forEach((script) => script.remove());
};

const Chatbot = () => {
  useEffect(() => {
    ['chatbase-bubble-button', 'chatbase-bubble-window', 'chatbase-message-bubbles'].forEach(removeElement);
    removeScripts('script#chatbase-script');
  }, []);

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: ChatbotId,
      domain: "www.chatbase.co",
    };

    const script = document.createElement('script') as HTMLScriptElement & { chatbotId: string, domain: string };
    script.src = "https://www.chatbase.co/embed.min.js";
    script.id = 'chatbase-script';
    script.chatbotId = ChatbotId;
    script.domain = "www.chatbase.co";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      ['chatbase-bubble-button', 'chatbase-bubble-window', 'chatbase-message-bubbles'].forEach(removeElement);
      removeScripts('script#chatbase-script');
    };
  }, []);

  return null;
};

export default Chatbot;
