import React, { useMemo } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { isMobile } from 'react-device-detect';
import { IRouteProps } from '../../routes/routes';
// swipe gesture handler
// custom components

import { TopBar, SideBar, Content } from '../../components/Layout';

// auth context
import { useAuth } from '../../contexts/Auth';
import { useMenu } from '../../contexts/Menu';
// RBCA
import WithRole from '../roles/WithRole';
import Chatbot from '@/components/Chat';

const PrivateRoute: React.FC<IRouteProps> = ({
    component,
    exact,
    path,
    role,
}) => {
    const { isAuth, data } = useAuth();

    const location = useLocation();

    const { setCollapsed } = useMenu();
    const handlers = useSwipeable({
        delta: isMobile ? 75 : 1000,
        onSwipedRight: () => isMobile && setCollapsed(false),
        onSwipedLeft: () => isMobile && setCollapsed(false),
        trackTouch: true,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true,
    });

    if (!isAuth) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location },
                }}
            />
        );
    }

    const RouteElement = () => {
        return (
            <Route
                exact={exact}
                path={path}
                component={
                    isAuth
                        ? component
                        : () => (
                              <Redirect
                                  to={{ pathname: '/login', state: location }}
                              />
                          )
                }
            />
        );
    };

    // RBAC ELEMENT
    const ProtectedComponent: any = useMemo(
        () => WithRole(data, role)(() => <RouteElement />),
        [component, path, data],
    );

    return (
        <div
            {...handlers}
            style={{
                display: 'flex',
                flexDirection: 'row',
                minHeight: '100vh',
                width: '100%',
            }}
        >
            <SideBar />
            <Content>
                <TopBar />
                {/* RBAC */}

                <ProtectedComponent />
                <Chatbot />

                {/* {(role === undefined ||
                    role?.includes(data?.role[0]?.type)) && (
                    <Route
                        exact={exact}
                        path={path}
                        component={
                            isAuth ? component : () => <Redirect to="/login" />
                        }
                    />
                )} */}
            </Content>
        </div>
    );
};

export default PrivateRoute;
