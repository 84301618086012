import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
// custom components
import ItemGroup from './ItemGroup';
import SoloMenuItem from './SoloMenuItem';
import SubMenu from './SubMenu';

import { StyledMenu, StyledMenuItem } from './styles';
// context
import { useMenu } from '../../../contexts/Menu';
// RBAC
import WithAdmin from '../../../helpers/roles/WithAdmin';
import WithShopManager from '../../../helpers/roles/WithShopManager';
import { getFormattedRemoteConfig } from '../../../utils/remoteConfig';
import { authContext } from '../../../contexts/Auth';

type UsersPlanogram = {
    users: Array<string>;
};

const Menu: React.FC = () => {
    const history = useHistory();
    const { isCollapsed, setCollapsed } = useMenu();

    const featureFlag = getFormattedRemoteConfig();

    const usersPlanogram: UsersPlanogram = JSON.parse(
        featureFlag.AUTOMATION_PLANOGRAM_USERS || '{}',
    );

    const { data } = useContext(authContext);

    const getMenuProps = (pathname: string) => {
        return {
            key: pathname,
            onClick: () => {
                setCollapsed(true);
                history.push(pathname);
            },
        };
    };

    const handlers = useSwipeable({
        delta: 75,
        onSwipedLeft: () => setCollapsed(true),
        trackTouch: true,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true,
    });

    // protected menus
    const ProtectedAdminMenu = WithAdmin(() => (
        <StyledMenu
            mode={!isCollapsed ? 'vertical' : 'inline'}
            selectedKeys={[history.location.pathname]}
        >
            <SubMenu
                isCollapsed={!isCollapsed}
                key="sub1"
                title="Usuários"
                icon={null}
            >
                <ItemGroup key="g1" title="Administrador">
                    <StyledMenuItem {...getMenuProps('/users/admin/list')}>
                        Gerenciar Administradores
                    </StyledMenuItem>
                </ItemGroup>

                <ItemGroup key="g2" title="Lojista">
                    <StyledMenuItem
                        {...getMenuProps('/users/shopmanager/list')}
                    >
                        Gerenciar Lojistas
                    </StyledMenuItem>
                </ItemGroup>
                <ItemGroup key="g3" title="Cliente">
                    <StyledMenuItem {...getMenuProps('/users/client/list')}>
                        Gerenciar Clientes
                    </StyledMenuItem>
                </ItemGroup>
            </SubMenu>
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Lojas"
                {...getMenuProps('/stores')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Estoque"
                {...getMenuProps('/stocks/list')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Produtos"
                {...getMenuProps('/product_global/list')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Vendas"
                {...getMenuProps('/sales/list')}
            />

            {featureFlag.PUSH_NOTIFICATION_V2 === 'true' ? (
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Notificações PUSH"
                    {...getMenuProps('/notifications/v2/list')}
                />
            ) : (
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Notificações PUSH"
                    {...getMenuProps('/notifications/list')}
                />
            )}

            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Importações"
                {...getMenuProps('/imports/reports')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Logs de notificação"
                {...getMenuProps('/notification/logs')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Anúncios"
                {...getMenuProps('/adverts/admin/list')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Comunicados"
                {...getMenuProps('/announcements')}
            />
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Boletos e Cobranças"
                {...getMenuProps('/invoice')}
            />
            {featureFlag.CHARGE_LOOSE === 'true' && (
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Cobrança Automática Avulsa"
                    {...getMenuProps('/charges')}
                />
            )}
        </StyledMenu>
    ));

    const ProtectedShopManagerMenu = WithShopManager(() => (
        <StyledMenu
            mode={!isCollapsed ? 'vertical' : 'inline'}
            selectedKeys={[history.location.pathname]}
        >
            <SubMenu
                isCollapsed={!isCollapsed}
                key="sub9"
                title="Gerenciar"
                icon={null}
            >
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Lojas"
                    {...getMenuProps('/stores/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Produtos"
                    {...getMenuProps('/products/list')}
                />

                {featureFlag.PUSH_NOTIFICATION_V2 === 'true' ? (
                    <SoloMenuItem
                        isCollapsed={!isCollapsed}
                        title="Notificações PUSH"
                        {...getMenuProps('/notifications/v2/list')}
                    />
                ) : (
                    <SoloMenuItem
                        isCollapsed={!isCollapsed}
                        title="Notificações PUSH"
                        {...getMenuProps('/notifications/list')}
                    />
                )}

                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Anúncios"
                    {...getMenuProps('/adverts/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Transferências"
                    {...getMenuProps('/transfer/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Saldo em Carteira"
                    {...getMenuProps('/wallet/balance')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Cupons"
                    {...getMenuProps('/coupons/list')}
                />
                {featureFlag.AUTOMATION_PLANOGRAM === 'true' && (
                    <SoloMenuItem
                        isCollapsed={!isCollapsed}
                        title="Planogramas"
                        {...getMenuProps('/planogram/automation')}
                    />
                )}
                {featureFlag.AUTOMATION_PLANOGRAM === 'false' &&
                    usersPlanogram?.users?.includes(
                        `${data?.user?.id as number}`,
                    ) && (
                        <SoloMenuItem
                            isCollapsed={!isCollapsed}
                            title="Planogramas"
                            {...getMenuProps('/planogram/automation')}
                        />
                    )}

                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Usuários"
                    {...getMenuProps('/shopmanagers/users/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Boletos e Cobranças"
                    {...getMenuProps('/invoice')}
                />
            </SubMenu>
            <SubMenu
                isCollapsed={!isCollapsed}
                key="sub6"
                title="Relatórios"
                icon={null}
            >
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Estoques"
                    {...getMenuProps('/stocks/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Ajustes de Estoque"
                    {...getMenuProps('/stocks/adjustments')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Compras"
                    {...getMenuProps('/purchase/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Vendas"
                    {...getMenuProps('/sales/list')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Financeiro"
                    {...getMenuProps('/financial')}
                />
                <SoloMenuItem
                    isCollapsed={!isCollapsed}
                    title="Importações"
                    {...getMenuProps('/imports/reports')}
                />
            </SubMenu>
            <SoloMenuItem
                isCollapsed={!isCollapsed}
                title="Comunicados"
                {...getMenuProps('/announcements/view')}
            />
        </StyledMenu>
    ));

    return (
        <div {...handlers} style={{ height: '100%' }}>
            <StyledMenu
                mode={!isCollapsed ? 'vertical' : 'inline'}
                selectedKeys={[history.location.pathname]}
            >
                <ItemGroup>
                    <SoloMenuItem
                        isCollapsed={!isCollapsed}
                        title="Dashboard"
                        icon={null}
                        {...getMenuProps('/')}
                    />

                    <ProtectedShopManagerMenu />
                    <ProtectedAdminMenu />
                </ItemGroup>
            </StyledMenu>
        </div>
    );
};

export default Menu;
