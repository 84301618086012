import React, { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import ptBR from 'antd/lib/locale/pt_BR';
import { QueryClientProvider } from '@tanstack/react-query';
import ContextBind from './contexts';
import Router from './routes';
import GlobalStyles from './styles/global';
//
import { PageContainer } from './components/Layout';
//
import 'antd/dist/antd.css';
import queryClient from './store/queryClient';
import AnnouncementProvider from './providers/announcement';
import FlagsProvider from './contexts/Flags';
//
moment.locale('pt-br');
//
const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={ptBR}>
                <ContextBind>
                    <GlobalStyles />
                    <Suspense fallback={<div />}>
                        <AnnouncementProvider>
                            <PageContainer>
                                <FlagsProvider>
                                    <Router />
                                </FlagsProvider>
                            </PageContainer>
                        </AnnouncementProvider>
                    </Suspense>
                </ContextBind>
            </ConfigProvider>
        </QueryClientProvider>
    );
};

export default App;
