import { createGlobalStyle, css, keyframes } from 'styled-components';
import { lighten } from 'polished';

const shakeHorizontal = keyframes`
  0%{
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%{
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%{
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  30%{
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40%{
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  50%{
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60%{
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  70%{
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80%{
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90%{
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  100%{
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
`;

const GlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        #root {
            display: flex;
            justify-content: center;
            background-color: ${theme.colors.backgroundColor};
            max-width: 100vw;
            overflow-x: hidden;
            overflow-y: auto;
            a {
                text-decoration: none;
            }
        }
        html,
        body {
            height: 100%;
            font-size: 62.5%;
        }
        .ant-layout-sider-trigger {
            background: ${theme.colors.accentColor};
        }
        .ant-form-inline,
        .ant-form-item {
            margin-bottom: 8px !important;
        }
        .ant-row {
            margin: 15px 0 !important;
        }
        // buttons
        .default-btn {
            width: 100%;
            color: ${theme.colors.white};
            background-color: ${theme.colors.accentColor};
            border: ${theme.colors.accentColor};

            &:hover {
                color: ${theme.colors.white};
                background-color: ${lighten(0.1, theme.colors.accentColor)};
            }
            &:focus {
                color: ${theme.colors.white};
                background-color: ${theme.colors.accentColor};
                border: ${theme.colors.accentColor};
            }
        }
        .success-btn {
            width: 100%;
            color: ${theme.colors.white};
            background-color: ${theme.colors.sucess};
            border: ${theme.colors.sucess};

            &:hover {
                color: ${theme.colors.white};
                background-color: ${lighten(0.1, theme.colors.sucess)};
            }
            &:focus {
                color: ${theme.colors.white};
                background-color: ${theme.colors.sucess};
                border: ${theme.colors.sucess};
            }
        }
        .green-btn {
            width: 100%;
            color: ${theme.colors.white};
            background-color: ${theme.colors.green};
            border: ${theme.colors.green};

            &:hover {
                color: ${theme.colors.white};
                background-color: ${lighten(0.1, theme.colors.green)};
            }
            &:focus {
                color: ${theme.colors.white};
                background-color: ${theme.colors.green};
                border: ${theme.colors.green};
            }
        }
        // animations
        .shakeX-animation {
            animation-name: ${shakeHorizontal};
            animation-duration: 1s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
        }
        // logo border
        .store-logo {
            & div {
                border-radius: 7px !important;
            }
        }
        // Table Row Colors
        .row-added {
            & > :not(td:last-of-type) {
                background-color: ${theme.colors.sucess};
                font-weight: bold;
                opacity: 0.7;
            }
            &:hover > :not(td:last-of-type) {
                * {
                    color: ${theme.colors.sucess} !important;
                }
            }
        }
        .row-updated {
            & > :not(td:last-of-type) {
                background-color: ${theme.colors.accentColor};
                font-weight: bold;
                opacity: 0.7;
            }
            &:hover > :not(td:last-of-type) {
                * {
                    color: ${theme.colors.accentColor} !important;
                }
            }
        }
        .row-deleted {
            & > :not(td:last-of-type) {
                background-color: ${theme.colors.fail};
                font-weight: bold;
                opacity: 0.7;
            }
            &:hover > :not(td:last-of-type) {
                * {
                    color: ${theme.colors.fail} !important;
                }
            }
        }
        .row-disabled {
            background-color: rgb(220, 220, 220);
        }
    `}
  `;

export default GlobalStyles;
